// @ts-nocheck
import React, { Component, Fragment, useState, useEffect } from "react";
import defaultImage from '@/image/car_holder.jpg';

import * as ImgCDN from '@zuzuche/imgcdn';
import { check, clean } from 'webpcheck';

const ZZC_CDN_DOMAIN = /imgcdn(\d+)\.zuzuche\.com/;
const ZZC_VEHICLE_CDN_DOMAIN = 'zzccdn.zuzuche.com';
const ZZC_NEW_CDN_DOMAIN = 'static.zuzuche.com';
const ZZC_CDN_DOMAIN_UPAIYUN = 'upaiyun.com';

/**
 * 是否为cdn图片
 * @param {*} url 图片路径
 */
function isZZCCDN(url) {
    return url && (
        ZZC_CDN_DOMAIN.test(url)
        || url.indexOf(ZZC_VEHICLE_CDN_DOMAIN) > -1
        || url.indexOf(ZZC_NEW_CDN_DOMAIN) > -1
        || url.indexOf(ZZC_CDN_DOMAIN_UPAIYUN) > -1
    );
}
/**
 * 格式化CDN
 * 若是cdn网络图片，则经CDN格式化
 * 否则其他网络图片或者本地图片不进行压缩，直接返回
 * @param {string} param0 src 图片cdn地址
 * @param {object} param1 opt 格式化配置可选项 opt查看文档[https://docs.upyun.com/cloud/image/#_11]
 * opt常用参数：
 * 1、opt.fw: 宽，如 300 [限定宽度，高度自适应]
 * 2、opt.quality: 整数值，如 75 [设置压缩质量，可选范围[1-99]]
 * 3、opt.format: 图片格式，如 jpg [输出格式，可选值 jpg、png、webp。webp 包含动态 WebP]
 * 建议quality必传
 * fw是情况决定
 */
export function imageFormat({ src, opt = {} }) {
    // 检查是否支持webp
    if (check()) {
        opt.format = 'webp';
    }
    if (isZZCCDN(src)) {
        return ImgCDN.append(src, opt);
    }
    if (src) {
        return src;
    }

    return defaultImage;
}

/**
 * EasyImage组件用于统一图片处理
 * 1、图片CDN质量优化、格式化
 * 2、“烂图”处理
 * ...等
 */


/**
 * 对于图片出现异常时，只要切换图片显示，并没有其他行为要处理的时候，多加一个状态码是多余的。
 * 一般情况下，强烈建议使用此组件，此组件消除了EasyImage2组件存在的问题
 * 因此，只需要e.target.onerror设置成空，取消掉错误处理，
 * 以防极端情况下我们的错误图片也获取不到，导致循环调用错误方法；接着给img赋新值即可
 * @param {string} param0 src 图片url 必传
 * @param {string} param1 failbackSrc 图片加载失败时显示的占位图url 可选
 * @param {object} param2 opt 图片压缩参数配置项 可选
 */
export default function EasyImage({ src, failbackSrc = defaultImage, opt = {}, ...props }) {
    let [online, setOnline] = useState(true);
    // 加一个断网判断
    useEffect(() => {
        if(!navigator.onLine) {
            setOnline(false)
        }
        window.addEventListener('offline',function(){
            setOnline(false)
        })
    })
    return <img
        src={imageFormat({ src, opt })}
        onError={(e) => {
            /**
             * 为防止默认占位图也加载失败的情况，
             * 添加一个条件判断
             * 若是在图片加载失败时，且此时图片加载的图片不等于默认图片
             * 那么设置为加载默认图片
             * 否则若加载出错时图片的src就是默认图片
             * 则说明默认图片加载也失败了
             * 此时不应该再设置图片的src为默认图片
             * 如此可解决诸如网络断掉、默认图片丢失等造成循环加载失败的问题【图片加载失败了 => 加载默认图片失败 => 再次加载默认图片又失败 => ...套娃...】
             * TIPS:实际上e.target.onerror = null;毫无作用，根本防止不了循环加载错误
             */
            if (e.target.src !== failbackSrc && online) {
                if (props.report) {
                    window.za && window.za('ea.carImageError',{
                        src: src,
                        ...(props.reportInfo || {})
                    })
                }
                e.target.onerror = null;
                e.target.src = failbackSrc
            }
            // e.target.onerror = null;
            // e.target.src = failbackSrc
        }}
        {...props}
    />
}




/**
 * 遵守react状态驱动的图片错误处理组件
 * 此组件由于componentWillReceiveProps setState 存在异步问题
 * 对于遍历的列表而言，需要在父组件更新数据时更新该组件数据，不太建议使用
 * 若是在图片加载失败或者加载完成时想要有其他行为要处理（比如，加载完成红因此loading占位图等）
 * 则建议使用此组件
 */
export class EasyImage2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            src: props.src,
            errored: false
        };
    }

    handleOnError = () => {
        if (!this.state.errored) {
            this.setState({
                src: this.props.failbackSrc || defaultImage,
                errored: true
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.src != nextProps.src) {
            this.setState({
                src: nextProps.src
            })
        }
    }
    // componentWillUnmount() {
    //     // 不清除webp检查结果了，
    //     // clean()
    // }

    handleOnLoad = () => {

    }

    render() {
        const {
            src
        } = this.state;
        const {
            src: _1,
            failbackSrc: _2,
            opt = {},
            ...props
        } = this.props;

        return (
            <Fragment>
                <img
                    src={imageFormat({ src, opt })}
                    onLoad={this.handleOnLoad}
                    onError={this.handleOnError}
                    {...props}
                />
            </Fragment>

        );
    }
}

/**
 * EasyImage3用于处理背景图情况
 * @param {string} param0 src 图片url 必传
 * @param {string} param1 failbackSrc 图片加载失败时显示的占位图url 可选
 * @param {object} param2 opt 图片压缩参数配置项 可选
 */

export function EasyImage3({ src, failbackSrc = defaultImage, opt = {}, ...props }) {
    return <div style={{ backgroundImage: src ? `url(${imageFormat({ src, opt })})` : `url(${failbackSrc})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat' }} {...props}></div>
}

